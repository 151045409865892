import * as React from 'react';
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import Container from '@mui/material/Container';

import './Video.css';
import ControlIcons from './ControlIcons';
import { OnProgressProps } from 'react-player/base';
import { useAppSelector } from '../../app/store';
import { VideoInfo, PreviewProps } from '../../app/commonInterfaces';
import { selectVideoById } from '../../reducer/videoSlice';
import { useParams } from 'react-router-dom';

const format = (seconds: number) => {
  if (isNaN(seconds)) {
    return '00:00'
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
  } else {
    return `${mm}:${ss}`
  }
};

const Video = () => {
  const { videoId } = useParams();
  //console.log(`video = ${videoId}`)
  const video: VideoInfo | undefined = useAppSelector(state => selectVideoById(state, Number(videoId)))
  //console.log(`video = ${JSON.stringify(video)}`)

  const [playerstate, setPlayerState] = useState({
    playing: true,
    muted: true,
    volume: 0.5,
    playerbackRate: 1.0,
    played: 0,
    seeking: false,
  })


  //Destructure State in other to get the values in it
  const { playing, muted, volume, playerbackRate, played, seeking } = playerstate;
  const playerRef = useRef(null);
  const playerDivRef = useRef(null);

  //This function handles play and pause onchange button
  const handlePlayAndPause = () => {
    setPlayerState({ ...playerstate, playing: !playerstate.playing })
  }

  const handleMuting = () => {
    setPlayerState({ ...playerstate, muted: !playerstate.muted })
  }

  const handleVolumeChange = (event: Event, value: number, activeThumb: number) => {
    setPlayerState({ ...playerstate, volume: value / 100, muted: value === 0 ? true : false, });
  }

  const handleVolumeSeek = (event: Event | React.SyntheticEvent<Element, Event>, value: number) => {
    setPlayerState({ ...playerstate, volume: value / 100, muted: value === 0 ? true : false, });
  }

  const handlePlayerRate = (rate: any) => {
    setPlayerState({ ...playerstate, playerbackRate: rate });
  }

  const handlePlayerProgress = (state: OnProgressProps) => {
    console.log('onProgress', state);
    if (!playerstate.seeking) {
      setPlayerState({ ...playerstate, ...state });
    }
    console.log('afterProgress', state);
  }

  const handlePlayerSeek = (event: Event, value: number, activeThumb: number) => {
    setPlayerState({ ...playerstate, played: value / 100 });
    //playerRef.current.seekTo(newValue / 100);
    // console.log(played)
  }

  const handlePlayerMouseSeekDown = (e: any) => {
    setPlayerState({ ...playerstate, seeking: true });
  }

  const handlePlayerMouseSeekUp = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
    setPlayerState({ ...playerstate, seeking: false });
    //playerRef.current.seekTo(newValue / 100);
  }

  const handleRewind = () => {
    //playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    console.log(played)
  }

  const handleFastForward = () => {
    //playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
    console.log(played)
  }

  const handleFullScreenMode = () => {
    //  screenfull.toggle(playerDivRef.current)
    console.log(played)
  }

  //const currentPlayerTime = playerRef.current ? playerRef.current.getCurrentTime() : '00:00';
  //const movieDuration =  playerRef.current ? playerRef.current.getDuration() : '00:00';
  const currentPlayerTime = 0;
  const movieDuration = 0;
  const playedTime = format(currentPlayerTime);
  const fullMovieTime = format(movieDuration);
  
  if (video === undefined) {
    return  <Container maxWidth="md">No Video</Container>
  } else {
    return (
      <>
        <header className='header__section'>
          <p className='header__text'>{video.title}</p>
        </header>
        <Container maxWidth="md">
          <div className='playerDiv' ref={playerDivRef}>
            <ReactPlayer width={'100%'} height='100%'
              ref={playerRef}
              url={process.env.PUBLIC_URL + video.urlVideo}
              playing={playing}
              volume={volume}
              playbackRate={playerbackRate}
              onProgress={handlePlayerProgress}
              muted={muted} />

            <ControlIcons
              key={volume.toString()}
              playandpause={handlePlayAndPause}
              playing={playing}
              rewind={handleRewind}
              fastForward={handleFastForward}
              muting={handleMuting}
              muted={muted}
              //volumeChange={handleVolumeChange}
              //volumeSeek={handleVolumeSeek}
              volume={volume}
              playerbackRate={playerbackRate}
              playRate={handlePlayerRate}
              fullScreenMode={handleFullScreenMode}
              played={played}
              //onSeek={handlePlayerSeek}  
              onSeekMouseUp={handlePlayerMouseSeekUp}
              onSeekMouseDown={handlePlayerMouseSeekDown}
              playedTime={playedTime}
              fullMovieTime={fullMovieTime}
            />
          </div>
        </Container>
      </>
    );
  }
}

export default Video;