import * as React from 'react';
import Typography from '@mui/material/Typography';
import { CommentInfo, CommentProps } from '../../app/commonInterfaces';
import { useAppSelector } from '../../app/store';
import { selectCommentById } from '../../reducer/commentSlice';

const Comment = ({ commentId }: CommentProps) => {

    const comment: CommentInfo | undefined = useAppSelector(state => selectCommentById(state, commentId))

    return (
        <Typography paragraph>
            {comment?.description}
        </Typography>
    )
}

export default Comment