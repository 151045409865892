import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { fetchComments, selectCommentIds } from '../../reducer/commentSlice';
import Comment from './Comment';

const Comments = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(fetchComments());
    }, []);

    const commentIds = useAppSelector(selectCommentIds)
    console.log(commentIds)

    return (
        <CardContent>
            <Typography paragraph>Method:</Typography>
            {commentIds.map((id) => (
                <Comment commentId={id} />
            ))}
            <Typography>
                Set aside off of the heat to let rest for 10 minutes, and then serve.
            </Typography>
        </CardContent>
    )
}

export default Comments