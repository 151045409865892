import * as React from 'react';
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from '../../app/store';
import { fetchVideos, selectVideoIds } from './../../reducer/videoSlice'
import Preview from './Preview';

const Previews = () => {
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    dispatch(fetchVideos());
  }, []);

  const videoIds = useAppSelector(selectVideoIds)
  console.log(videoIds)
  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      {videoIds.map((id) => (         
          <Preview videoId={id}/>
        ))}
    </Typography>
  )
}

export default Previews;
