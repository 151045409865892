import { createServer } from 'miragejs';

export function makeServer() {
    return createServer({
        routes() {
            this.get("/api/videos", () => (
                [
                    { id: 1, title: "Test 1", uploadData: "03/01/2023", urlPrev: "./media/Kaguya.png", urlVideo: "/media/Kaguya.mp4", description: "Test 1 description ", owner: "Kotay" }
                    , { id: 2, title: "Test 2", uploadData: "04/23/2023", urlPrev: "./media/Kaguya.png", urlVideo: "/media/Kaguya.mp4", description: "Test 2 description ", owner: "Kotay" }
                    , { id: 3, title: "Test 2", uploadData: "05/07/2023", urlPrev: "./media/Kaguya.png", urlVideo: "/media/Kaguya.mp4", description: "Test 3 description ", owner: "Kotay" }
                ]
            ))

            this.get("/api/videos/:id/comments", () => (
                [
                    { id: 1, title: "Test 1", uploadData: "03/01/2023", description: "Test 1 description ", owner: "Kotay" }
                    , { id: 2, title: "Test 1", uploadData: "03/01/2023", description: "Test 1 description ", owner: "Kotay" }                
                ]
            ))
        },
    })
}