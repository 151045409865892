import * as React from 'react';
import {
  Routes,
  Route,  
  // Redirect,
} from 'react-router-dom';

import LoginPage from './components/LoginPage';
import ProtectedPage from './components/ProtectedPage';
import NotFound from './components/NotFound';
import AdminDashboard from './components/AdminDashboard';
import AuthProvider from './components/AuthProvider';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import Content from './components/Content';
import Video from './components/videoPlayer/Video';
import AboutMe from './components/AboutMe';
import ContactMe from './components/ContactMe';

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Content />} />          
          <Route path="/video/:videoId" element={<Video />} />          
          <Route path="/login" element={<LoginPage />} />
          <Route path="/about" element={<AboutMe />} />     
          <Route path="/contact" element={<ContactMe />} />     
          <Route element={<RequireAuth />}>
            <Route path="/protected" element={<ProtectedPage />} />
            <Route path="/dashboard" element={<AdminDashboard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>        
      </Routes>
    </AuthProvider>
  );
}