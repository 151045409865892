import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { VideoInfo, PreviewProps } from '../../app/commonInterfaces';
import { useAppSelector } from '../../app/store';
import { selectVideoById } from '../../reducer/videoSlice';
import { EntityId } from '@reduxjs/toolkit';
import { Link, useNavigate } from "react-router-dom";
import Comments from '../comment/Comments';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Preview = ({ videoId }: PreviewProps) => {
    const navigate = useNavigate();

    const video: VideoInfo | undefined = useAppSelector(state => selectVideoById(state, videoId))
    const [expanded, setExpanded] = React.useState(false);

    //console.log(`Preview video = ${JSON.stringify(video)}`)

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const getStyle = () => {
        return  !isSmUp ? null : { display: 'flex' }
    }

    if (video === undefined) {
        return <Card sx={{ my: 5, mx: 2 }}>No Video</Card>
    } else {
        return (
            <Card sx={{ my: 5, mx: 2 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            E
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={video.title}
                    subheader={video.uploadData}
                />

                <CardContent sx={getStyle}>
                    <Link to={`./video/${video.id}`}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={video.urlPrev}
                            alt="Paella dish"
                        />
                    </Link>
                    <Typography variant="body2" color="text.secondary">
                        This impressive paella is a perfect party dish and a fun meal to cook
                        together with your guests. Add 1 cup of frozen peas along with the mussels,
                        if you like.
                    </Typography>
                </CardContent>


                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites">
                        <FavoriteIcon />
                    </IconButton>
                    <IconButton aria-label="share">
                        <ShareIcon />
                    </IconButton>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Comments />
                </Collapse>
            </Card>
        );
    }
}

export default Preview;