import { configureStore } from '@reduxjs/toolkit'
import videoReducer from './../reducer/videoSlice'
import commentReducer from './../reducer/commentSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    console.log(err)
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState)
  } catch (err) {
    console.log(err)
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    videoReducer: videoReducer,
    commentReducer: commentReducer,
  },
})

store.subscribe(() => saveToLocalStorage(store.getState()))

export type RootState = ReturnType<typeof store.getState>
export const useAppDispatch:() => typeof store.dispatch = useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;

