import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice, isFulfilled } from '@reduxjs/toolkit'
import { CommentInfo } from "../app/commonInterfaces";
import { RootState } from '../app/store';
import axios from 'axios';

const commentAdapter = createEntityAdapter<CommentInfo>(
  {
    // Assume IDs are stored in a field other than `book.id`
    selectId: (video) => video.id,
    // Keep the "all IDs" array sorted based on book titles
    sortComparer: (a, b) => a.title.localeCompare(b.title),
  }
)

const initialState = commentAdapter.getInitialState({
  status: 'idle',
  error: null
})

export const fetchComments = createAsyncThunk('comments/fetchEntities', async () => {
  const requestUrl = "/api/videos/:id/comments";
  return axios.get<CommentInfo[]>(requestUrl);
});

export const commentSlice = createSlice({
  name: 'comments',
  initialState: initialState,
  reducers: {
    videoAdded: commentAdapter.addOne,
    videosReceived(state, action) {
      console.log("action: " + JSON.stringify(action))
      commentAdapter.setAll(state, action.payload.comments)
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(fetchComments), (state, action) => {
        state.status = 'succeeded'
        console.log("action1: " + JSON.stringify(action.payload.data))
        commentAdapter.setAll(state, action.payload.data)
      })
  }
})

export default commentSlice.reducer

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllComments,
  selectById: selectCommentById,
  selectIds: selectCommentIds
  // Pass in a selector that returns the posts slice of state
} = commentAdapter.getSelectors<RootState>(state => state.videoReducer)



